<template>
    <section class="edit-category">
        <Loader :loading="showLoader" />
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title float-left">Add Banner</h3>
                                <router-link class="float-right" to="/website-management/banners">
                                    <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="pt-2" @submit.prevent="addBannerPage">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Banner Type</label>
                                                <select class="custom-select form-control" v-model="addBanner.type"
                                                    :class="{
                                                        'is-invalid': submitted && $v.addBanner.type.$error,
                                                    }">
                                                    <option selected> </option>
                                                    <option value="Promotion banner"> Promotion Banner </option>
                                                    <option value="Main Slider"> Main Slider </option>
                                                    <option value="First Group of banners">First Group of banners
                                                    </option>
                                                </select>
                                                <div v-if="submitted && !$v.addBanner.type.required"
                                                    class="invalid-feedback">
                                                    Type is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Title</label>
                                                <input v-model="addBanner.title" type="text" class="form-control"
                                                    :class="{
                                                        'is-invalid': submitted && $v.addBanner.title.$error,
                                                    }" />
                                                <div v-if="submitted && !$v.addBanner.title.required"
                                                    class="invalid-feedback">
                                                    Title is required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Sub Title </label>
                                                <input type="text" v-model="addBanner.subtitle" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">URL</label>
                                                <input type="text" class="form-control" v-model="addBanner.url" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for=""> Status </label>
                                                <div class="form-group">
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="addBanner.status" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Short Description</label>
                                                <textarea class="form-control" v-model="addBanner.short_description"
                                                    rows="5"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Banner</label>
                                                <b-button v-b-modal.modal-xl class="choose-image">Choose
                                                    Image</b-button>

                                                <chooseImage @imageId="setImageId"></chooseImage>
                                                <vue-dropzone ref="myVueDropzone" id="thumbVideo "
                                                    :options="dropzoneOptions" :useCustomSlot="true"
                                                    v-on:vdropzone-success="success" @vdropzone-sending="updateAvater">
                                                    <div class="dropzone-custom-content">
                                                        <div class="dropzone-custom-content">
                                                            <div v-if="bannerImg">
                                                                <div class="image-size-dropzone">
                                                                    <img :src="`${imageApiURL}/${bannerImg.thumb_s}`"
                                                                        class="show-img img-fluid" />
                                                                </div>
                                                                <div class="subtitle pt-1 pb-0">
                                                                    <span> Change </span>
                                                                    <span style="color: #eb2027"> Browse</span>
                                                                </div>
                                                            </div>
                                                            <div v-if="!bannerImg" class="pt-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="43"
                                                                    height="43" viewBox="0 0 24 24" fill="transparent"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-upload" color="">
                                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4">
                                                                    </path>
                                                                    <polyline points="17 8 12 3 7 8"></polyline>
                                                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                                                </svg>
                                                                <div class="subtitle pt-1 pb-5">
                                                                    <span> Drop File or </span>
                                                                    <span style="color: #eb2027"> Browse</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </vue-dropzone>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group float-right mb-0 pt-3">
                                        <button class="btn btn btn-add btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import { setingsApi } from "../../../api";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import vue2Dropzone from "vue2-dropzone";
import { required } from "vuelidate/lib/validators";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Loader from "../../../components/Loader";
import chooseImage from "../../../components/chooseImage";
export default {
    name: "add-invoice",
    components: {
        Loader, chooseImage,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            showLoader: false,
            addBanner: {
                title: "",
                subtitle: "",
                type: "",
                url: "",
                short_description: "",
                images: ""
            },
            bannerImg: "", imageApiURL,
            options: [],
            dropzoneOptions: {
                url: `${mediaApiURL}/file/upload`,
            },
            submitted: false,
        };
    },
    validations: {
        addBanner: {
            type: { required },
            title: { required },
        },
    },
    mounted() { },
    methods: {
        setImageId(selectedId) {
            this.showLoader = true;
            axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
                this.bannerImg = response.data.data[0];
                this.showLoader = false;
            });
        },
        updateAvater(file, xhr, formData) {
            let folder = "products";
            xhr.setRequestHeader("Header", "");
            formData.append("folder", folder);
        },
        success(file, response) {
            this.addBanner.images = response.data.id;
            this.bannerImg = ""
            if (response.status == "success") {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: "Updated successfully",
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: "The given data was invalid",
                });
            }
        },
        async addBannerPage() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.showLoader = true;

            this.addBanner.images = [this.bannerImg.id]
            const data = await setingsApi.addBanner({
                ...this.addBanner,
            });
            this.showLoader = false;
            this.$router.push("/website-management/banners");
            if (data.status == 200) {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: data.messages,
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: data.messages,
                });
            }
        },
    },
};
</script>

<style scoped>
.dropzone {
    padding: 40px 20px !important;
    min-height: 175px !important;
}
</style>
